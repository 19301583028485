/** @jsx jsx */
import { jsx, Styled, Flex, Box, Grid } from 'theme-ui';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import Accordion from '../../components/Accordion';
import FullHeightColumn from '../../components/FullHeightColumn';
import { showNotification } from '../../state/notifications';
import { locNavigate } from '../../state/session';
import * as api from '../../utils/api';
import { formatTimeWithoutSecond, formatDuration, formatPrice } from '../../utils/formatters';
import { capitalize } from '../../utils/string';
import { BookingWidget } from '../../components/widgets';
import AccountLayout from '../../account/AccountLayout';
import Spinner from '../../components/Spinner';
import * as analytics from '../../utils/analytics';
import moment from 'moment-timezone';
import BusTicketsFromRl from '../../components/BusTicketsFromRl';

const BusStationIcon = ({ title }) => (
  <svg width="16" height="13" viewBox="0 0 16 13" xmlns="http://www.w3.org/2000/svg">
    {title && <title>{title}</title>}
    <g fill="#001E60" fillRule="nonzero">
      <path d="M10.68 2.842a.16.16 0 01-.12-.154v-.69c0-1.06-.86-1.92-1.92-1.92H2.88C1.82.079.96.939.96 1.999v.69a.16.16 0 01-.12.155c-.493.126-.838.57-.84 1.078v1.28c.001.51.347.954.84 1.08a.16.16 0 01.12.155v2.603a1.91 1.91 0 00.886 1.607.16.16 0 01.074.135v.82a1.12 1.12 0 002.24 0v-.482a.16.16 0 01.16-.16H7.2a.16.16 0 01.16.16v.482a1.12 1.12 0 002.24 0v-.82a.16.16 0 01.074-.135c.55-.35.884-.955.886-1.607V6.436a.16.16 0 01.12-.155c.493-.126.839-.57.84-1.08v-1.28a1.117 1.117 0 00-.84-1.08zm-7.16 6.36h-.64a.48.48 0 110-.96h.64a.48.48 0 110 .96zm5.12 0H8a.48.48 0 110-.96h.64a.48.48 0 110 .96zm.64-2.403a.16.16 0 01-.16.16H2.4a.16.16 0 01-.16-.16v-4.8a.64.64 0 01.64-.64h5.76a.64.64 0 01.64.64v4.8zM13.6 3.921a1.757 1.757 0 00-.736 3.356.16.16 0 01.093.145v4.66a.64.64 0 001.28 0V7.423a.16.16 0 01.094-.145A1.757 1.757 0 0013.6 3.92z" />
    </g>
  </svg>
);

const Discount = ({ discount: { discountTypeName, quantity, amount, returnTrip }, translate }) => {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '3fr 1fr 1fr' }}>
      <Box>
        {discountTypeName} (
        {returnTrip ? translate('account.myTickets.return') : translate('account.myTickets.outbound')})
      </Box>
      <Box>
        {+quantity} {translate('account.myTickets.amount')}
      </Box>
      <Box sx={{ textAlign: 'right' }}>{formatPrice(amount)}</Box>
    </Box>
  );
};

const SelectedSeat = ({ selectedSeat: { selectedSeatName, quantity, amount, returnTrip }, translate }) => {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '3fr 1fr 1fr' }}>
      <Box>
        {selectedSeatName} (
        {returnTrip ? translate('account.myTickets.return') : translate('account.myTickets.outbound')})
      </Box>
      <Box>
        {+quantity} {translate('account.myTickets.amount')}
      </Box>
      <Box sx={{ textAlign: 'right' }}>{formatPrice(amount)}</Box>
    </Box>
  );
};

const ExtraCharges = ({ discount: { extraChargeName, quantity, amount }, translate }) => {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '3fr 1fr 1fr' }}>
      <Box>{extraChargeName}</Box>
      <Box>
        {+quantity} {translate('account.myTickets.amount')}
      </Box>
      <Box sx={{ textAlign: 'right' }}>{formatPrice(amount)}</Box>
    </Box>
  );
};

const SelectedServices = ({ service: { service, quantity, amount, returnTrip }, translate }) => {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '3fr 1fr 1fr' }}>
      <Box>
        {service} ({returnTrip ? translate('account.myTickets.return') : translate('account.myTickets.outbound')})
      </Box>
      <Box>
        {+quantity} {translate('account.myTickets.amount')}
      </Box>
      <Box sx={{ textAlign: 'right' }}>{formatPrice(amount)}</Box>
    </Box>
  );
};

const TotalCharges = ({ totalSums: { totalVatIncludedAmount }, translate }) => {
  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: '3fr 1fr 1fr' }}>
      <Box sx={{ fontWeight: 'medium' }}>{translate('account.myTickets.totalPaid')}</Box>
      <Box />
      <Box sx={{ textAlign: 'right', fontWeight: 'medium' }}>{formatPrice(totalVatIncludedAmount)}</Box>
    </Box>
  );
};

const Passengers = ({ passenger: { firstName, lastName } }) => {
  return (
    <>
      {firstName}
      &nbsp;
      {lastName}
      <br />
    </>
  );
};

const Place = ({ place, time, translate, arrival = false }) => (
  <Box sx={{ textAlign: arrival ? 'right' : 'left' }}>
    <Box sx={{ fontSize: [4, 5], fontWeight: 'heading' }}>{formatTimeWithoutSecond(time)}</Box>
    <Box sx={{ fontSize: [2, 3], fontWeight: 'medium' }}>{place.placeName}</Box>
    <Box sx={{ fontSize: 1 }}>
      <Box>{place.stopAreaName}</Box>
      <Flex
        sx={{
          alignItems: 'center',
          justifyContent: arrival ? 'flex-end' : 'flex-start',
        }}
      >
        <BusStationIcon title={translate('account.myTickets.stopName')} />
        <Box sx={{ ml: 1 }}>{place.stopName}</Box>
      </Flex>
    </Box>
  </Box>
);

const TripMainDetails = ({ translate, departureDate, arrivalDate, departureType, duration, fromPlace, toPlace }) => {
  return (
    <>
      <Box sx={{ fontSize: 1, fontWeight: 'normal' }}>
        {departureType}
        {duration && ', ' + formatDuration(duration)}
      </Box>
      <Grid sx={{ gridTemplateColumns: '1fr auto 1fr' }}>
        <Place translate={translate} place={fromPlace} time={departureDate} />
        <Box
          sx={{
            flex: 'none',
            px: [3, 4],
            fontSize: 5,
            textAlign: 'center',
            alignSelf: 'center',
          }}
        >
          &rarr;
        </Box>
        <Place translate={translate} place={toPlace} time={arrivalDate} arrival />
      </Grid>
    </>
  );
};

const DepartureDetails = ({ translate, line, companies }) => {
  return (
    <Box>
      {translate('account.myTickets.departureType')}: {line.name}, {line.departureType}, {line.number}
      <br />
      {translate('account.myTickets.operator')}: {companies.map(company => company.name).join(', ')}
    </Box>
  );
};

const SeatReservations = ({ seatReservations }) => (
  <>
    {seatReservations &&
      seatReservations
        .filter(sr => sr.vehicleNumber || sr.seatReservation)
        .map((seatReservation, ix) => (
          <Fragment key={ix}>
            {seatReservation.vehicleNumber ? seatReservation.vehicleNumber + ', Paikkavaraus ' : ''}
            {seatReservation.seatReservation ? seatReservation.seatReservation : ''}
            <br />
            {seatReservation.reservationNumber ? 'Varausnumero: ' + seatReservation.reservationNumber : ''}
          </Fragment>
        ))}
  </>
);

const PartTrip = ({ trip, seatReservations, translate }) => {
  let dDate = moment(trip.fromPlace.dateTime)
    .tz('Europe/Helsinki')
    .format('HH.mm');
  let aDate = moment(trip.toPlace.dateTime)
    .tz('Europe/Helsinki')
    .format('HH.mm');
  return (
    <Box>
      <Box>
        {trip.fromPlace.placeName}, {trip.fromPlace.stopAreaName} ({dDate}) &rarr; {trip.toPlace.placeName},{' '}
        {trip.toPlace.stopAreaName} ({aDate})
      </Box>
      <Box>
        {translate('account.myTickets.stopName')} {trip.fromPlace.stopName}
      </Box>
      <SeatReservations seatReservations={seatReservations} />
      <DepartureDetails translate={translate} line={trip.line} companies={trip.companies} />
    </Box>
  );
};

const TripDetails = ({ trip, seatReservations, translate, language, returnTrip = false }) => {
  const { fromPlace, toPlace, line, duration, companies, partTrips } = trip;
  let dDate = moment(trip.fromPlace.dateTime).tz('Europe/Helsinki');
  let aDate = moment(trip.toPlace.dateTime).tz('Europe/Helsinki');

  return (
    <Box mb={3} sx={{ fontSize: 1 }}>
      {returnTrip && <Box> {capitalize(dDate.locale(language).format('dddd L'))}</Box>}

      <TripMainDetails
        translate={translate}
        departureDate={dDate}
        arrivalDate={aDate}
        departureType={line.departureType}
        duration={duration}
        fromPlace={fromPlace}
        toPlace={toPlace}
      />

      {partTrips &&
        partTrips.map((partTrip, ix) => {
          dDate = moment(partTrip.fromPlace.dateTime).tz('Europe/Helsinki');
          aDate = moment(partTrip.toPlace.dateTime).tz('Europe/Helsinki');
          const partTripSeatReservations = seatReservations.filter(
            seatReservation => seatReservation.partTripId === partTrip.id && seatReservation.connectionId === trip.id
          );
          return (
            <Box key={ix} mt={3}>
              <Box sx={{ mb: 1, fontWeight: 'medium' }}>{ix + 1}. Matkaosuus</Box>
              <PartTrip translate={translate} trip={partTrip} seatReservations={partTripSeatReservations} />
            </Box>
          );
        })}
      {(!partTrips || partTrips.length == 0) && (
        <Box mt={3}>
          <DepartureDetails translate={translate} line={line} companies={companies} />
          <SeatReservations seatReservations={seatReservations.filter(sr => sr.connectionId === trip.id)} />
        </Box>
      )}
    </Box>
  );
};

const TicketCard = ({ ticket: { departureTime, arrivalTime, json }, language, translate }) => {
  const trip = json.items[0];
  const {
    selectedDiscounts,
    selectedExtraCharges,
    selectedServices,
    passengerInfo,
    seatReservations,
    selectedSeats,
    totalSums,
  } = json;

  let returnTrip = null;
  if (json.items[1]) {
    returnTrip = json.items[1];
  }

  const dDate = moment(trip.fromPlace.dateTime).tz('Europe/Helsinki');

  return (
    <Accordion
      title={
        <Flex sx={{ justifyContent: 'space-between', pr: 3, fontSize: 2 }}>
          <Box>
            {trip.fromPlace.placeName || trip.fromPlace.stopAreaName}
            <span sx={{ px: 2 }}>&rarr;</span>
            {trip.toPlace.placeName || trip.toPlace.stopAreaName}
          </Box>
          <Box sx={{ fontWeight: 'normal' }}>{capitalize(dDate.locale(language).format('dddd L'))}</Box>
        </Flex>
      }
    >
      <Box sx={{ fontSize: 1, maxWidth: '60ch' }}>
        <TripDetails trip={trip} seatReservations={seatReservations} translate={translate} language={language} />
        {returnTrip && (
          <TripDetails
            trip={returnTrip}
            seatReservations={seatReservations}
            translate={translate}
            language={language}
            returnTrip={true}
          />
        )}

        <Box mt={3}>
          {selectedDiscounts &&
            selectedDiscounts.map((discount, index) => (
              <Discount key={index} discount={discount} translate={translate} />
            ))}
          {selectedSeats &&
            selectedSeats.map((selectedSeat, index) => (
              <SelectedSeat key={index} selectedSeat={selectedSeat} translate={translate} />
            ))}
          {selectedServices &&
            selectedServices.map((service, index) => (
              <SelectedServices key={index} service={service} translate={translate} />
            ))}
          {selectedExtraCharges &&
            selectedExtraCharges.map((discount, index) => (
              <ExtraCharges key={index} discount={discount} translate={translate} />
            ))}
          {totalSums && <TotalCharges totalSums={totalSums} translate={translate} />}
        </Box>

        <Box mt={3}>
          {passengerInfo && passengerInfo.passengers && (
            <>
              {passengerInfo.passengers.length}
              &nbsp;
              {translate('account.myTickets.passenger')}
              <br />
              {passengerInfo.passengers.map((passenger, index) => (
                <Passengers key={index} passenger={passenger} translate={translate} />
              ))}
            </>
          )}
        </Box>
      </Box>
    </Accordion>
  );
};

const MyTicketsPage = ({ pageContext }) => {
  analytics.usePageCategory('matkustajat');
  const translate = getTranslate(useSelector(state => state.localize));
  const dispatch = useDispatch();
  const locale = useSelector(state => state.session.locale);
  const languages = {
    sv: 'sv-SE',
    en: 'en-US',
    default: 'fi-FI',
  };
  const language = languages[locale] || languages['default'];
  const [futureTickets, setFutureTickets] = useState();
  const [pastTickets, setPastTickets] = useState();
  const [showView, setShowView] = useState('future');
  const hasUser = useSelector(state => !!(state.session && state.session.user));

  useEffect(() => {
    (async () => {
      // already loaded
      if (futureTickets && pastTickets) {
        return;
      }
      if (!hasUser) {
        if (api.getToken() === null) {
          return dispatch(locNavigate('/login', '?afterLogin=/my-pages/my-tickets'));
        }
        return;
      }
      try {
        const promises = [api.getTickets('true'), api.getTickets('false')];
        const tickets = await Promise.all(promises.map(promise => promise.catch(e => e)));
        if (!(tickets[0] instanceof Error)) {
          setFutureTickets(tickets[0].sort((a, b) => a.departureTime > b.departureTime));
        } else {
          dispatch(showNotification('genericApiError'));
        }
        if (!(tickets[1] instanceof Error)) {
          setPastTickets(tickets[1].sort((a, b) => a.departureTime < b.departureTime));
        } else {
          dispatch(showNotification('genericApiError'));
        }
      } catch (error) {
        dispatch(showNotification('genericApiError'));
      }
    })();
  }, [hasUser, dispatch, pastTickets, futureTickets]);

  const title = translate('account.myTickets.title');

  return (
    <AccountLayout
      title={title}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      sidebar={
        <Box>
          <BookingWidget sidebar />
        </Box>
      }
    >
      <FullHeightColumn>
        <Box sx={{ flex: 'auto', position: 'relative' }}>
          {/**<Alert children={translate('account.myTickets.notification')} />*/}
          <Styled.h1 sx={{ m: 0 }}>{translate('account.myTickets.title')}</Styled.h1>
          {(!futureTickets || !pastTickets) && <Spinner size="medium" />}

          {futureTickets && futureTickets.length === 0 && pastTickets && pastTickets.length === 0 && (
            <div sx={{ mt: 3 }}>{translate('account.myTickets.noTickets')}</div>
          )}

          {futureTickets &&
            futureTickets.map(t => <TicketCard key={t.id} ticket={t} language={language} translate={translate} />)}
          {pastTickets &&
            pastTickets.map(t => <TicketCard key={t.id} ticket={t} language={language} translate={translate} />)}
          <Box sx={{ mt: 4 }}>
            <BusTicketsFromRl type="myTickets" />
          </Box>
        </Box>
      </FullHeightColumn>
    </AccountLayout>
  );
};

export default MyTicketsPage;
