/** @jsx jsx */
import { jsx, Flex, Box } from 'theme-ui';
import { useLanguage, useTranslate } from '../utils/getLanguage';
import { useIsAndroid, useIsIOS } from '../hooks/useMobileOperatingSystem';

export default ({ type }) => {
  const translate = useTranslate();
  const language = useLanguage();
  const isAndroid = useIsAndroid();
  const isIOS = useIsIOS();
  return (
    <Box sx={{ bg: 'blueLighter', p: 3, pb: 2, borderRadius: 1 }}>
      <div>{translate(`account.${type}.seeInRL`)}</div>
      {!isIOS && !isAndroid && (
        <Flex sx={{ mt: 3, justifyContent: 'center' }}>
          <a href="https://play.google.com/store/apps/details?id=com.kyyti.ride.matkahuolto&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
            <img
              alt={translate('account.myCards.downloadGooglePlay')}
              src={`/play_button/${language}.png`}
              sx={{ maxHeight: '50px', mr: 2 }}
            />
          </a>
          <a href="https://apps.apple.com/fi/app/reitit-ja-liput/id1496304929">
            <img
              alt={translate('account.myCards.downloadAppStore')}
              src={`/app_store/${language}.svg`}
              sx={{ maxHeight: '50px', p: 1 }}
            />
          </a>
        </Flex>
      )}
      {isIOS && (
        <a
          sx={{ display: 'flex', alignItems: 'center', textDecoration: 'underline' }}
          href="https://apps.apple.com/fi/app/reitit-ja-liput/id1496304929"
        >
          {translate('account.myCards.downloadRl')}
        </a>
      )}
      {isAndroid && (
        <a
          sx={{ display: 'flex', alignItems: 'center', textDecoration: 'underline' }}
          href="https://play.google.com/store/apps/details?id=com.kyyti.ride.matkahuolto&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          {translate('account.myCards.downloadRl')}
        </a>
      )}
    </Box>
  );
};
